import { Route } from '@angular/router';

export const DIALOGS_ROUTES: Route[] = [
    {
        path: 'messages',
        title: 'Диалоги',
        data: {
            isNav: true,
            navTitle: 'Диалоги',
            permissionId: 'dialogs',
        },
        loadChildren: () =>
            import('@brandPages/dialogs/messages/messages.routes').then(
                (mod) => mod.MESSAGES_ROUTES
            ),
    },
    {
        path: 'settings',
        title: 'Настройки',
        data: {
            isNav: true,
            navTitle: 'Настройки',
            permissionId: 'dialogs-settings',
        },
        loadComponent: () =>
            import('./settings/settings.component').then(
                (m) => m.SettingsComponent
            ),
    },
    {
        path: '**',
        pathMatch: 'full',
        redirectTo: '/dialogs/messages',
    },
];
